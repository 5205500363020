import React from 'react';

// Slides
import {
  Title,
  WhoAmI,
  ModulePresentation,
  WhoAreYou,
  Summary
} from './Opening';

import { Thank } from './Ending';
import Content from './Content';

export default function Part() {
  return (
    <>
      {/* Opening */}
      <Title />
      <WhoAmI />
      <ModulePresentation />
      <WhoAreYou />
      <Summary />

      {/* Content */}
      <Content />

      {/* Ending */}
      <Thank />
    </>
  );
}
