import React from 'react';

export default function CheatSheet() {
  const tableContent = [
    {
      command: 'snster print',
      description:
        'Génère une cartographie du réseau MI-LXC et affiche la topologie.'
    },
    {
      command: 'snster create',
      description: "Génère l'environnement (uniquement les nouvelles machines)"
    },
    {
      command: 'snster start',
      description: 'Démarre les machines.'
    },
    {
      command: 'snster attach [machine]',
      description: 'Obtenez un shell sur une machine spécifique.'
    },
    {
      command: 'snster display [machine]',
      description: 'Ouvre un GUI sur une machine spécifique'
    },
    {
      command: 'snster destroy [machine]',
      description:
        'Détruit une machine pour pouvoir la regénerer avec snster create'
    },
    {
      command: 'snster stop',
      description: "Éteint proprement l'environnement MI-LXC."
    }
  ];
  return (
    <section>
      <h2>Cheat Sheet des commandes</h2>
      <p>
        Toujours se placer dans le répertoire <em>/root/mi-lxc/</em> pour
        éxecuter les commandes
      </p>
      <div className="beautify-table custom">
        <div className="beautify-table-head">
          <table>
            <thead>
              <tr className="head">
                <th className="column1">Commande</th>
                <th className="column2 fill">Description</th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="beautify-table-body">
          <table>
            <tbody>
              {tableContent.map((command, idx) => (
                <tr key={idx} className="body">
                  <td className="column1">
                    <code>{command.command}</code>
                  </td>
                  <td className="column2 fill">{command.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
