import React from 'react';
import Arp from './Reminders/Arp';
import Spoofing from './Spoofing';
import PracticalWork from './Spoofing/PraticalWork';

export default function Content() {
  const styles = {
    heading: {
      color: 'white'
    },
    imgMusk: {
      width: '600px',
      height: '600px'
    }
  };
  return (
    <>
      <section data-background-color="CadetBlue">
        <h1 style={styles.heading}>Rappels ARP</h1>
      </section>
      <Arp />

      <Spoofing />

      <PracticalWork />
    </>
  );
}
