import React from 'react';
import arpspoofDoc from '../../../assets/img/arpspoofDoc.png';
import etterCapDoc from '../../../assets/img/ettercap.png';
import arpoisonDoc from '../../../assets/img/arpoison.png';

export default function Tools() {
  const styles = {
    heading: {
      color: 'white'
    },
    img: {
      width: '30%',
      height: '30%'
    },
    imgBig: {
      width: '40%',
      height: '40%',
      marginBottom: '20%'
    }
  };
  return (
    <>
      <section data-background-color="CadetBlue">
        <h1 style={styles.heading}>Outils</h1>
      </section>
      <section>
        <h2>arpspoof</h2>
        <img
          style={{ ...styles.imgBig, backgroundColor: 'white' }}
          src={arpspoofDoc}
          alt="arpspoof doc"
        />
      </section>

      <section>
        <h2>Ettercap</h2>
        <img
          style={{ ...styles.imgBig, backgroundColor: 'white' }}
          src={etterCapDoc}
          alt="Ettercap doc"
        />
      </section>

      <section>
        <h2>arpoison</h2>
        <img
          style={{ ...styles.imgBig, backgroundColor: 'white' }}
          src={arpoisonDoc}
          alt="arpoison doc"
        />
      </section>
    </>
  );
}
