import React from 'react';

export default function Question234() {
  return (
    <>
      <section>
        <h2>Question 2-3-4</h2>

        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column2 fill ">Question</th>
                  <th className="column2 fill ">Réponse</th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 2:</strong> Depuis la machine isp-a-home,
                    ouvrez un navigateur pour vous connecter à
                    `http://www.target.milxc`. Vous accédez à une page Dokuwiki.
                    Savez-vous sur quel serveur cette page est hébergée ?
                  </td>
                  <td className="fragment">target-dmz</td>
                </tr>

                <tr className="body">
                  <td className="column2 fill fragment">
                    <strong>Question 3:</strong> Depuis la machine isp-a-home,
                    ouvrez un navigateur pour vous connecter au site web de
                    l'UBS. Cela fonctionne-t-il ? Cette page est-elle hébergée
                    dans l'infrastructure MI-LXC ?
                  </td>
                  <td className="fragment fill ">
                    La page est en dehors de mi-lxc (routée par lxcbr0)
                  </td>
                </tr>

                <tr className="body">
                  <td className="column2 fill  fragment">
                    <strong>Question 4:</strong> Ouvrez un shell sur la machine
                    target-dmz (commande attach, donc). Installez le package
                    nano grâce à l'outil `apt` et vérifiez que vous pouvez
                    maintenant éditer des fichiers avec nano.
                  </td>
                  <td className="fragment"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
