import React from 'react';

export default function Summary() {
  const summary = [
    {
      name: 'Rappels',
      list: ['Fonctionnement ARP', 'Structure paquet']
    },
    {
      name: 'ARP Spoofing',
      list: ['Principe', "Types d'attaques", 'Outils', 'TP', 'Correction']
    }
  ];

  const styles = {
    title: {
      marginBottom: '90px'
    },
    summaryList: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  };

  return (
    <section>
      <h2 style={styles.title}>Plan</h2>
      <div style={styles.summaryList}>
        {summary.map(section => (
          <ul key={section.name}>
            <h3 className="fragment fade-in" style={styles.sectionHeader}>
              {section.name}
            </h3>
            {section.list.map(elt => (
              <li key={elt} style={styles.listItem}>
                <p className="fragment fade-in">{elt}</p>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </section>
  );
}
