import React from 'react';

export default function Question1() {
  const ASHeaders = [
    {
      name: 'Nom',
      addr: 'Adresses/Domaine',
      description: 'Description'
    }
  ];
  const ASList = [
    {
      name: 'NS ROOT O',
      addr: '(ASN 5, 100.100.0.0/24)',
      description: 'est un serveur DNS root'
    },
    {
      name: 'NS ROOT P',
      addr: '(ASN 6, 100.100.1.0/24)',
      description: 'est un serveur DNS root'
    },

    {
      name: 'OpenDNS',
      addr: '(ASN 7, 100.100.100.0/24)',
      description: 'est un résolveur openDNS'
    },

    {
      name: 'TLD milxc',
      addr: '(ASN 8, 100.100.20.0/24)',
      description: "est l'opérateur TLD pour .milxc"
    },
    {
      name: 'La cible',
      addr: '(ASN 10, 100.80.0.0/16, target.milxc)',
      description: 'est une entreprise intéressante à attaquer'
    },
    {
      name: 'Ecorp',
      addr: '(ASN 11, 100.81.0.0/16, ecorp.milxc)',
      description: 'est une entreprise maléfique utilisée pour attaquer'
    },

    {
      name: 'MICA',
      addr: '(ASN 12, 100.82.0.0/16, mica.milxc)',
      description: "est un CA, prêt pour ACME (du style à let's encrypt)"
    },
    {
      name: 'Gozilla',
      addr: '(ASN 13, 100.83.0.0/16, gozilla.milxc)',
      description:
        "est un éditeur de navigateur web (ie, Mozilla), autorisé à diffuser de nouveaux certificats d'AC racine aux navigateurs"
    },

    {
      name: 'ISP-A',
      addr: '(ASN 20, 100.120.0.0/16, ispa.milxc)',
      description: "est un ISP d'utilisateur final"
    },
    {
      name: 'Transit-A',
      addr: '(ASN 30, 100.64.0.0/24)',
      description: 'est un opérateur de transit'
    },
    {
      name: 'Transit-B',
      addr: '(ASN 31, 100.64.1.0/24)',
      description: 'est un opérateur de transit'
    }
  ];
  return (
    <>
      <section>
        <h2>Question 1</h2>
        <a href="https://github.com/flesueur/mi-lxc/blob/master/doc/DETAILS.fr.md">
          Source:
          https://github.com/flesueur/mi-lxc/blob/master/doc/DETAILS.fr.md
        </a>
        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                {ASHeaders.map(({ name, addr, description }, idx) => (
                  <tr key={idx} className="head">
                    <th className="column1">{name}</th>
                    <th className="column2 ">{addr}</th>
                    <th className="column3 ">{description}</th>
                  </tr>
                ))}
              </thead>
            </table>
          </div>
          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                {ASList.map(({ name, addr, description }, idx) => (
                  <tr key={idx} className="body">
                    <td className="column1">{name}</td>
                    <td className="column2 ">{addr}</td>
                    <td className="column3 ">{description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
