import React from 'react';
import Presentation from './Presentation';
import Installation from './Installation';
import Tree from './Tree';
import CheatSheet from './CheatSheet';
import Topology from './Topology';
import Exercice from './Exercice';
import Correct from './Correct/Correct';

export default function Milxc() {
  return (
    <section>
      <Presentation />
      <Installation />
      <Tree />
      <Topology />
      <CheatSheet />
      <Exercice />
      <section>
        <h2 className="r-fit-text">Correction</h2>
      </section>
      <Correct />
    </section>
  );
}
