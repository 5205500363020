import React from 'react';
// import imgReact from '../../assets/img/react.png';
import imgSlideShow from '../../assets/img/hardening.png';

export default function Title() {
  const style = {
    headImg: {
      width: '600px',
      height: '600px'
    }
  };

  return (
    <section id="part-3">
      <img data-src={imgSlideShow} style={style.headImg} alt="SlideShow" />
      <h2>
        <span className="white">
          BUT3 <small>2023</small>
        </span>
      </h2>
      <h3>
        <span className="white">Sécurité des systèmes d'information</span>
      </h3>
      <h3>HTTPS</h3>
    </section>
  );
}
