import React from 'react';
import Question1 from './Question1';
import Question234 from './Question234';
import AsCreation from './AsCreation';

export default function Correct() {
  return (
    <>
      <Question1 />

      <Question234 />

      <AsCreation />
    </>
  );
}
