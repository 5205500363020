import React from 'react';
import layer8Img from '../../../assets/img/layer8.jpg';
import cveImg from '../../../assets/img/cve.png';
import zeroDayImg from '../../../assets/img/zeroday.jpg';
import imgElon from '../../../assets/img/hackerMusk.png';
import iotImg from '../../../assets/img/iot.jpg';

export default function Threats() {
  const styles = {
    heading: {
      color: 'white'
    }
  };
  return (
    <section>
      <section data-background-color="CadetBlue">
        <h1 style={styles.heading}>Les Menaces</h1>
      </section>

      <section>
        <h2>Menaces internes</h2>
        <p>
          Les menaces internes, qu'elles soient intentionnelles ou
          accidentelles, représentent un risque important. Les employés ont
          parfois accès à des informations sensibles, et il est essentiel de
          mettre en place des mesures de prévention et de détection.
        </p>
        <img data-src={layer8Img} alt="Layer 8 problem" />
      </section>

      <section>
        <h2>Menaces externes</h2>
        <ul>
          <li>Espionnage industriel</li>
          <li>Enjeux politiques</li>
          <li>Motivation financière (revente de données)</li>
        </ul>
        <img data-src={imgElon} alt="Elon" />
      </section>

      <section>
        <h2>Vulnérabilités logicielles</h2>
        <p>
          Les vulnérabilités dans les logiciels et les applications sont
          exploitées par les attaquants pour compromettre des systèmes. Les
          mises à jour régulières et les tests de sécurité sont essentiels pour
          réduire ces risques.
        </p>
        <img data-src={cveImg} alt="CVE" />
        <img data-src={zeroDayImg} alt="ZeroDay" />
      </section>

      <section>
        <h2>Complexité des infrastructures</h2>
        <ul>
          <li>Architecture de plus en plus complexes</li>
          <li>Cloud provider et IoT en expansion</li>
          <li>Surface d'attaque accrue </li>
        </ul>
        <img data-src={iotImg} alt="Internet of Things" />
      </section>
    </section>
  );
}
