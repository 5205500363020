import React from 'react';

// Slides
import { Title, Summary } from './Opening';

import { Thank } from './Ending';
import Content from './Content';

export default function Part() {
  return (
    <>
      {/* Opening */}
      <Title />
      <Summary />

      {/* Content */}
      <Content />

      {/* Ending */}
      <Thank />
    </>
  );
}
