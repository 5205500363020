import React from 'react';
import CyberDefinition from './Reminders/Quote';
import CiaTriad from './Reminders/CiaTriad';
import Risks from './Reminders/Risks';
import Threats from './Reminders/Threats';
import Milxc from './Milxc';
import Protections from './Reminders/Protections';

export default function Content() {
  const styles = {
    heading: {
      color: 'white'
    },
    imgMusk: {
      width: '600px',
      height: '600px'
    }
  };
  return (
    <>
      <section data-background-color="CadetBlue">
        <h1 style={styles.heading}>Rappels cybersécurité</h1>
      </section>

      <section>
        <CyberDefinition />
      </section>

      <CiaTriad />

      <Risks />

      <Threats />

      <Protections />

      <Milxc />
    </>
  );
}
