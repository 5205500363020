import React from 'react';
import { randomMacAddr } from '../../../../utils';

const data = [
  { hostname: 'target-router', ip: '100.80.0.1', mac: '56:f8:b2:80:a4:11' },
  { hostname: 'target-admin', ip: '100.80.0.4', mac: '56:f8:b2:80:a4:11' },
  { hostname: 'target-commercial', ip: '100.80.0.2', mac: '56:f8:b2:80:a4:11' },
  { hostname: 'target-dev', ip: '100.80.0.3', mac: '56:f8:b2:80:a4:11' },
  { hostname: 'target-dmz', ip: '100.80.1.2', mac: '56:f8:b2:80:a4:11' },
  { hostname: 'target-ldap', ip: '100.80.0.10', mac: '56:f8:b2:80:a4:11' },
  { hostname: 'target-filer', ip: '100.80.0.6', mac: '56:f8:b2:80:a4:11' },
  { hostname: 'target-intranet', ip: '100.80.0.5', mac: '56:f8:b2:80:a4:11' }
];

export default function Question1() {
  return (
    <>
      <section>
        <h2>Plan d'addressage</h2>

        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column1  ">Hostname</th>
                  <th className="column2  ">IP</th>
                  <th className="column3  ">mac</th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                {data.map((elt, idx) => (
                  <tr className="body" key={idx}>
                    <td className="column1 ">
                      <strong>{elt.hostname}</strong>
                    </td>
                    <td className="column2 ">{elt.ip}</td>
                    <td className="column3 ">{randomMacAddr()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
