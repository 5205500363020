import React from 'react';

export default function GroupFile() {
  return (
    <>
      <section data-auto-animate>
        <h2>Ajout d'un hôte local</h2>

        <pre>
          <code
            data-trim
            data-noescape
            className="yaml"
            data-line-numbers="5|6,7|8,9|12">
            {`
  infra:
  network:
    interfaces:
      eth0:
        bridge: gozilla-lan
        ipv4: 100.83.0.2/16
        ipv6: 2001:db8:83::2/48
    gatewayv4: 100.83.0.1
    gatewayv6: 2001:db8:83::1
  templates:
    - resolv:
        domain: gozilla.milxc
        ns: 100.100.100.100
`}
          </code>
        </pre>
      </section>

      <section data-auto-animate>
        <h2>Ajout d'un hôte local</h2>

        <pre>
          <code
            data-trim
            data-noescape
            className="yaml"
            data-line-numbers="5|6,7|8,9|12">
            {`
  infra:
  network:
    interfaces:
      eth0:
        bridge: iut-lan
        ipv4: 100.64.2.10/24

    gatewayv4: 100.64.2.1

  templates:
    - resolv:
        domain: iut.milxc
        ns: 100.100.100.100
`}
          </code>
        </pre>
      </section>
    </>
  );
}
