import React from 'react';

export default function Question456() {
  return (
    <>
      <section>
        <h2>Question 4-5-6</h2>

        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column2 fill ">Question</th>
                  <th className="column2 fill ">Réponse</th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 4:</strong> Voyez-vous la table ARP changer
                    ? A votre avis, pourquoi ne change-t-elle pas ?
                  </td>
                  <td className="fragment">
                    L'entrée n'existe pas (pas de requête --> Reponse non prise
                    en compte)
                  </td>
                </tr>

                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 5:</strong> Depuis la machine du sysadmin,
                    faites un curl vers le serveur de l'intranet puis relancez
                    le watch. Une nouvelle entrée doit apparaître dans la table
                    ARP. Laquelle ? Pourquoi ?
                  </td>
                  <td className="fragment">
                    Une requête ARP a été faite pour l'intranet
                  </td>
                </tr>

                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 6:</strong> DRelancez python3 spoof.py sur
                    la machine du développeur. Est-ce que la table ARP sur la
                    machine du sysadmin a changé ? Expliquez.
                  </td>
                  <td className="fragment">
                    L'entrée dans la table ARP a éte mise à jour car existante
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
