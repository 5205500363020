import React from 'react';

import { List } from '../../../Generic';
import Correct from './Correct';

export default function PracticalWork() {
  const styles = {
    heading: {
      color: 'white'
    },
    img: {
      width: '30%',
      height: '30%'
    },
    imgBig: {
      width: '40%',
      height: '40%',
      marginBottom: '20%'
    }
  };
  return (
    <>
      <section data-background-color="CadetBlue">
        <h1 style={styles.heading}>TP</h1>
      </section>
      <section>
        <h2>Consignes</h2>
        <List
          list={[
            'Envoi enoncé par mail',
            "Rapport à alimenter pour l'avancement (markdown ou autre)"
          ]}
        />
      </section>

      <section data-background-color="CadetBlue">
        <h1 style={styles.heading}>Correction</h1>
      </section>

      <Correct />
    </>
  );
}
