import React from 'react';

export default function Question23() {
  return (
    <>
      <section>
        <h2>Question 2-3</h2>

        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column2 fill ">Question</th>
                  <th className="column2 fill ">Réponse</th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 2:</strong> Rappelez succintement la mise
                    en oeuvre de ce type d'attaque comme vu dans le cours.
                  </td>
                </tr>

                <tr className="body">
                  <td className="column2 fill fragment">
                    <strong>Question 3:</strong>{' '}
                    {`Sur l'ordinateur de l'administrateur système, affichez la table ARP. Est-ce que le serveur intranet est présent ?
S'il est présent, supprimez-le en utilisant la commande suivante: arp -d <ip_intranet> (en remplaçant <ip_intranet> par l'IP précédemment identifiée)`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
