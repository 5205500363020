import React from 'react';

export default function Question5() {
  return (
    <>
      <section>
        <h2>Question 5</h2>
        Montrez sur un exemple que l'ordre des règles compte. Pour modifier le
        filtrage, vous aurez besoin de supprimer des règles et d'en ajouter à
        des endroits spécifiques : référez-vous au manuel d'iptables.
        <pre>
          <code data-trim data-noescape className="fragment bash">
            {`
   # Append -A toujours bloque
   iptables -A INPUT -p tcp --dport 22 -j ACCEPT
   # Insert -I debloque    
   iptables -I INPUT -p tcp --dport 22 -j ACCEPT


`}
          </code>
        </pre>
      </section>

      <section>
        <h2>Question 5</h2>
        Mettez en place un jeu de règles autorisant le SSH sur le routeur
        uniquement depuis le LAN de l'entreprise (testable depuis la machine
        "target-admin" par exemple).
        <pre>
          <code data-trim data-noescape className="fragment bash">
            {`
     # flush INPUT chain
     iptables -F INPUT
     # Allow LAN
     iptables -A INPUT -i eth1 -p tcp --dport 22 -j ACCEPT
     # Reject all
     iptables -A INPUT -p tcp --dport 22 -j REJECT

`}
          </code>
        </pre>
        <h2 className="fragment">Résultat</h2>
        <pre>
          <code data-trim data-noescape className="fragment bash">
            {`
     root@mi-target-admin:~# ssh 100.80.0.1
     Sucess!

`}
          </code>
        </pre>
        <pre>
          <code data-trim data-noescape className="fragment bash">
            {`
     root@mi-isp-a-hacker:~# ssh root@100.64.0.10
     ssh: connect to host 100.64.0.10 port 22: Connection refused

`}
          </code>
        </pre>
      </section>

      <section>
        <h2>Modules iptables</h2>
        Créez une règle avec multiport autorisant les ports 22 et 53. N'hésitez
        pas à ajouter un commentaire pour y voir plus clair (plusieurs modules
        peuvent être utilisés simultanément).
        <pre>
          <code data-trim data-noescape className="fragment bash">
            {`
     iptables -A INPUT -m comment --comment "Allow TCP-22 TCP-53" -m multiport -p tcp --dports 22,53 -j ACCEPT

`}
          </code>
        </pre>
      </section>
    </>
  );
}
