import React from 'react';
import triadImg from '../../../assets/img/cia_triad.jpg';
import googlePrivacy from '../../../assets/img/google_privacy.jpg';
import haMeme from '../../../assets/img/ha_meme.png';
import integrityImg from '../../../assets/img/file-integrity-monitoring.jpg';
import { List } from '../../../Generic';

export default function CiaTriad() {
  const styles = {
    heading: {
      color: 'white'
    },
    img: {
      width: '30%',
      height: '30%'
    }
  };
  return (
    <>
      <section>
        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Enjeux de la cybersécurité</h1>
          <img style={styles.img} src={triadImg} alt="CIA triad" />
        </section>

        <section>
          <h2>Confidentialité</h2>
          <img src={googlePrivacy} alt="Google Privacy" />

          <List
            list={[
              'Accès aux données restreint uniquement aux personnes autorisées',
              'Chiffrement des données',
              'Authentification (Traçabilité)',
              'Multi Factor Authentication',
              'RGPD'
            ]}
          />
        </section>

        <section>
          <h2>Intégrité</h2>
          <img src={integrityImg} alt="File integrity" />

          <List
            list={[
              'Données intactes',
              'Signature/hash',
              'Versionning',
              'Utile contre les altération volontaires et accidentelles'
            ]}
          />
        </section>

        <section>
          <h2>Disponibilité</h2>
          <img src={haMeme} alt="High Availability meme" />
          <List
            list={[
              'Continuité de service',
              '99.99%',
              'Cluster (Redondance)',
              "Plan de reprise d'activité"
            ]}
          />
        </section>
      </section>
    </>
  );
}
