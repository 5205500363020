import React from 'react';
import arpSpoofing from '../../../assets/img/spoofing.png';

import Tools from './Tools';
import Attacks from './Attacks';

export default function Spoofing() {
  const styles = {
    heading: {
      color: 'white'
    },
    img: {
      width: '30%',
      height: '30%'
    },
    imgBig: {
      width: '40%',
      height: '40%',
      marginBottom: '20%'
    }
  };
  return (
    <>
      <section>
        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>ARP Spoofing</h1>
        </section>

        <section>
          <h2>Principe</h2>
          <img
            style={{ ...styles.imgBig, backgroundColor: 'white' }}
            src={arpSpoofing}
            alt="ARP Spoofing"
          />
        </section>
        <Attacks />
        <Tools />
      </section>
    </>
  );
}
