import React from 'react';

export default function GroupFile() {
  return (
    <>
      <section data-auto-animate>
        <h2>Création de l'AS</h2>

        <pre>
          <code
            data-trim
            data-noescape
            className="yaml"
            data-line-numbers="4|13,14|16,17,18|21|27">
            {`
version: 1

header:
  name: Gozilla AS

hosts:
  router:
    master: alpine
    network:
      interfaces:
        eth0:
          bridge: transit-a
          ipv4: 100.64.0.50/24
          ipv6: 2001:db8:b000::50/48
        eth1:
          bridge: gozilla-lan
          ipv4: 100.83.0.1/16
          ipv6: 2001:db8:83::1/48
    templates:
      - bgprouter:
          asn: 13
          asdev: eth1
          neighbors4: 100.64.0.1 as 30
          neighbors6: 2001:db8:b000::1 as 30
      - resolv:
          ns: 100.100.100.100
          domain: gozilla.milxc
`}
          </code>
        </pre>
      </section>

      <section data-auto-animate>
        <h2>Création de l'AS</h2>

        <pre>
          <code
            data-trim
            data-noescape
            className="yaml"
            data-line-numbers="4|13,14|16,17,18|21|27">
            {`
version: 1

header:
name: IUT AS

hosts:
router:
master: alpine
network:
interfaces:
  eth0:
    bridge: transit-a
    ipv4: 100.64.0.60/24

  eth1:
    bridge: iut-lan
    ipv4: 100.64.2.1/24

templates:
- bgprouter:
    asn: 14
    asdev: eth1
    neighbors4: 100.64.0.1 as 30

- resolv:
    ns: 100.100.100.100
    domain: iut.milxc
`}
          </code>
        </pre>
      </section>
    </>
  );
}
