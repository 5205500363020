import React from 'react';
import { List } from '../../../Generic';

export default function ModuleGoal() {
  const list = [
    'Comprendre les risques de sécurité',
    "Identifier les faiblesse d'un SI",
    'Implémenter des mécanismes de sécurité',
    "Identifier l'origine d'une attaque"
  ];

  return (
    <>
      <h1>
        <span className="white">Objectifs</span>
      </h1>
      <List list={list} />
    </>
  );
}
