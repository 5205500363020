import React from 'react';
import GroupFile from './GroupFile';
import asIutStep1 from '../../../../assets/img/milxc-iutAS-step1.png';
import asIutStep2 from '../../../../assets/img/milxc-iutAS-step2.png';
import GroupFileHost from './GroupFileHost';

export default function AsCreation() {
  const styles = {
    printImg: {
      width: '40%',
      height: 'auto'
    }
  };

  return (
    <>
      <section>
        <h2>Création de l'AS</h2>

        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column1  ">Nom</th>
                  <th className="column2  ">valeur</th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                <tr className="body">
                  <td className="column1  fragment">
                    <strong>Nom de l'AS</strong>
                  </td>
                  <td className="column2  fragment">IUT</td>
                </tr>
                <tr className="body">
                  <td className="column1  fragment">
                    <strong>Nom de domaine</strong>
                  </td>
                  <td className="column2  fragment">iut.milxc</td>
                </tr>
                <tr className="body">
                  <td className="column1  fragment">
                    <strong>Numéro d'AS</strong>
                  </td>
                  <td className="column2  fragment">14</td>
                </tr>
                <tr className="body">
                  <td className="column1  fragment">
                    <strong>Plage dans 100.64.0.0/10</strong>
                  </td>
                  <td className="column2  fragment">100.64.2.0/24</td>
                </tr>
                <tr className="body">
                  <td className="column1  fragment">
                    <strong>Ip LAN routeur</strong>
                  </td>
                  <td className="column2  fragment">100.64.2.1</td>
                </tr>
                <tr className="body">
                  <td className="column1  fragment">
                    <strong>Ip WAN routeur</strong>
                  </td>
                  <td className="column2  fragment">100.64.0.60</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <pre>
          <code data-trim data-noescape className="bash fragment">
            {`
        $ tree /root/mi-lxc

        ├── iut
        |   |
        │   ├── group.yml
        │
     
       
        `}
          </code>
        </pre>
      </section>

      <GroupFile />
      <section>
        <pre>
          <code data-trim data-noescape className="bash">
            {`
        $ snster print
        `}
          </code>
        </pre>
        <img
          style={styles.printImg}
          data-src={asIutStep1}
          alt="AS iut step 1"
        />
      </section>

      <GroupFileHost />
      <section>
        <pre>
          <code data-trim data-noescape className="bash">
            {`
        $ snster print
        `}
          </code>
        </pre>
        <img
          style={styles.printImg}
          data-src={asIutStep2}
          alt="AS iut step 2"
        />
      </section>
    </>
  );
}
