import React from 'react';

export default function Question12() {
  return (
    <>
      <section>
        <h2>Question 1-2</h2>

        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column2 fill ">Question</th>
                  <th className="column2 fill ">Réponse</th>
                </tr>
              </thead>
            </table>
          </div>

          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                <tr className="body">
                  <td className="column1 fragment">
                    <strong>Question 1:</strong> Expliquez ces 3 chaînes INPUT,
                    OUTPUT et FORWARD : à quels types de paquets
                    s'appliquent-elles ?
                  </td>

                  <td className="column2 fragment">
                    <span className="fragment">
                      <strong> INPUT</strong> : Appliquée aux paquets entrants,
                      elle contrôle les paquets destinés à la machine locale.
                      <br />
                    </span>
                    <span className="fragment">
                      <strong> OUTPUT</strong> : Appliquée aux paquets sortants,
                      elle contrôle les paquets générés par la machine locale.
                      <br />
                    </span>
                    <span className="fragment">
                      <strong> FORWARD</strong> : Appliquée aux paquets qui
                      transitent par la machine, elle contrôle le transfert des
                      paquets entre les interfaces.
                      <br />
                    </span>
                  </td>
                </tr>

                <tr className="body">
                  <td className="column1 fragment">
                    <strong>Question 2:</strong> Quelles sont les interfaces et
                    adresses IP internes et externes ?
                  </td>

                  <td className="column2 fragment">
                    <span className="fragment">
                      <strong> eth0 </strong> 100.64.0.10/24 - WAN
                      <br />
                    </span>
                    <span className="fragment">
                      <strong> eth1 </strong> 100.80.0.1/16 - LAN
                      <br />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
