import React from 'react';

export default function List({
  list,
  margin = '30px',
  className = 'fragment fade-in'
}) {
  const styles = {
    li: {
      marginTop: margin
    }
  };
  return (
    <>
      <ul>
        {list.map((elt, idx) => (
          <li style={styles.li} key={idx} className={className}>
            {elt}
          </li>
        ))}
      </ul>
    </>
  );
}
