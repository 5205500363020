import React from 'react';

export default function Tree() {
  return (
    <section>
      <h2>Arborescence du projet</h2>
      <pre>
        <code data-trim data-noescape className="bash">
          {`
        $ tree /root/mi-lxc

        ├── network1
        |   |
        │   ├── group.yml
        │
        ├── network2
        |   |
        │   ├── group.yml
        |
        ├── network3
        |
        |   ├──group.yml
        |   |
        |   ├──device1
        |   |   |
        |   |   ├──provision.sh
        |   |   ├──dns.conf
        |   |
        |   ├──webserver
        |       |
        |       ├──provision.sh
        |       ├──index.html
        |
        `}
        </code>
      </pre>
    </section>
  );
}
