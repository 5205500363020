import React from 'react';

export default function Question6() {
  return (
    <>
      <section>
        <h2>Question 6</h2>
        Nous avons ici utilisé l'action DROP et le client SSH met un certain
        temps à s'apercevoir du refus. Pour voir l'effet de la question
        suivante, tout d'abord bloquez tout en sortie avec cette politique :
        <pre>
          <code data-trim data-noescape className="fragment bash">
            {`
   iptables -P OUTPUT DROP

`}
          </code>
        </pre>
        <p className="fragment">
          Puis créez une règle pour autoriser, en sortie du firewall, uniquement
          les réponses à des connexions SSH entrantes (à destination du service
          SSH sur le firewall).{' '}
        </p>
        <strong className="fragment">
          Question 6 : Quelles commandes IPTables avez-vous tapées ?
        </strong>
        <pre>
          <code data-trim data-noescape className="fragment bash">
            {`
    # DROP policy output default
    iptables -P OUTPUT DROP
    # allow replies tcp22
    iptables -A OUTPUT -m comment --comment "Allow etablished ssh" -m state --state RELATED,ESTABLISHED 
    -p tcp --sport 22 -j ACCEPT


`}
          </code>
        </pre>
      </section>

      <section>
        <h2>Question 6 bis</h2>
        Attention si vous essayez d'accéder à distance au routeur avec par
        exemple la machine isp-a-hacker vous pourrez rencontrer des problèmes à
        cause des requêtes BCP qui ne passent plus.
        <br />
        <br />
        <strong className="fragment">
          Pour les autoriser procédez comme ci-dessous:
        </strong>
        <pre>
          <code data-trim data-noescape className="fragment bash">
            {`
            # Activation BGP en entrée
            iptables -I INPUT -p tcp --dport 179 -j ACCEPT

            # Activation réponses BGP
            iptables -I OUTPUT -p tcp --sport 179 -m state --state RELATED,ESTABLISHED -j ACCEPT

`}
          </code>
        </pre>
      </section>
    </>
  );
}
