import React from 'react';
import firewallImg from '../../assets/img/firewall_principle.png';
import nginxWafImg from '../../assets/img/nginx-waf.png';
import tcpFrameImg from '../../assets/img/tcp-frame.png';
import dmzStandardImg from '../../assets/img/dmz-standard.png';
import dmzFullImg from '../../assets/img/dmz-full.png';
import site2SiteImg from '../../assets/img/site-to-site-forti.jpg';
import netFilterImg from '../../assets/img/netfilter.png';
import iptablesImg from '../../assets/img/iptables.png';
import PracticalWork from './PraticalWork';

export default function Content() {
  const styles = {
    heading: {
      color: 'white'
    },
    li: {
      marginBottom: '20px'
    },
    imgBig: {
      width: '30%',
      height: 'auto'
    },
    arrow: { fontSize: '3rem' },
    message: { marginBottom: '30px' }
  };
  return (
    <>
      <section>
        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Principes généraux</h1>
        </section>
        <section data-auto-animate>
          <h1>Définition</h1>
          <img className="r-stretch" src={firewallImg} alt="firewall" />
        </section>

        <section data-auto-animate>
          <h1>Définition</h1>
          <img src={firewallImg} className="r-stretch" alt="firewall" />
          <ul>
            <li className="fragment" style={styles.li}>
              Protection du LAN
            </li>
            <li className="fragment" style={styles.li}>
              Blocage du WAN
            </li>
            <li className="fragment" style={styles.li}>
              Par défaut LAN vers WAN Autorisé
            </li>
            <li className="fragment" style={styles.li}>
              Par défaut WAN vers LAN Interdit
            </li>
          </ul>
        </section>

        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Types de pare-feu</h1>
        </section>

        <section>
          <h1>Packet Filtering Firewalls</h1>
          <ul>
            <li className="fragment" style={styles.li}>
              <strong>Fonction : </strong>Analyse les en-têtes des paquets
              réseau (adresse IP, port) et décide de les accepter ou de les
              refuser en fonction de règles prédéfinies.
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Avantages : </strong>
              Simple, rapide, adapté à la protection contre des attaques de
              base.
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Inconvénients : </strong>
              Ne prend pas en compte le contexte de la connexion.
            </li>
          </ul>
        </section>

        <section>
          <h1>Stateful Firewalls</h1>
          <ul>
            <li className="fragment" style={styles.li}>
              <strong>Fonction : </strong>Suivi de l'état de la connexion, prend
              des décisions en fonction de l'état actuel de la connexion.
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Avantages : </strong>
              Permet de suivre l'état des sessions, offre une meilleure sécurité
              en analysant le contexte de la connexion.
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Inconvénients : </strong>
              Peut être plus complexe à configurer.
            </li>
          </ul>
        </section>

        <section>
          <h1>Pare-feu Applicatif </h1>
          <ul>
            <li className="fragment" style={styles.li}>
              <strong>Fonction : </strong>Agit au niveau de la couche
              application en interagissant directement avec le trafic
              applicatif.
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Avantages : </strong>
              Fournit un contrôle plus granulaire, permet l'inspection
              approfondie des paquets.
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Inconvénients : </strong>
              Peut introduire une latence, nécessite une configuration
              spécifique pour chaque application.
            </li>
          </ul>
        </section>

        <section>
          <h1>Web Application Firewalls - WAF </h1>
          <ul>
            <li className="fragment" style={styles.li}>
              <strong>Fonction : </strong>Spécialisé dans la protection des
              applications web en analysant le trafic HTTP.
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Avantages : </strong>
              Protège contre les attaques spécifiques aux applications web
              (injections SQL, cross-site scripting, etc.).
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Inconvénients : </strong>
              Nécessite une connaissance approfondie des applications web pour
              une configuration efficace.
            </li>
          </ul>
        </section>

        <section>
          <h1>Web Application Firewalls - WAF </h1>
          <img
            className="r-stretch"
            style={{ backgroundColor: 'white' }}
            src={nginxWafImg}
            alt="nginx WAF"
          />
        </section>

        <section>
          <h1>Next-Generation Firewalls (NGFW)</h1>
          <ul>
            <li className="fragment" style={styles.li}>
              <strong>Fonction : </strong>Intègre des fonctionnalités avancées
              telles que l'inspection des applications, la détection
              d'intrusion, la prévention des menaces avancées, et souvent une
              visibilité approfondie sur le trafic réseau.
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Application + IDS + IPS</strong>
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Avantages : </strong>
              Fournit une sécurité plus avancée en analysant le trafic au niveau
              applicatif, en identifiant les applications spécifiques, et en
              offrant une protection contre des menaces sophistiquées.
            </li>
            <li className="fragment" style={styles.li}>
              <strong>Inconvénients : </strong>
              Plus couteux et plus complexe
            </li>
          </ul>
        </section>

        <section>
          <h1>Firewalls sous toutes les formes</h1>
          <ul>
            <li className="fragment" style={styles.li}>
              Physique
            </li>
            <li className="fragment" style={styles.li}>
              Virtuel
            </li>
            <li className="fragment" style={styles.li}>
              Cloud
            </li>
            <li className="fragment" style={styles.li}>
              OS
            </li>
          </ul>
        </section>

        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Fonctionnement</h1>
        </section>

        <section>
          <h1>Critères de filtrage paquet (stateless)</h1>
          <img className="r-stretch" src={tcpFrameImg} alt="tcp frame" />
        </section>

        <section>
          <h1>Critères de filtrage état (stateful)</h1>

          <ul>
            <li className="fragment" style={styles.li}>
              <strong>INVALID</strong> --- meaning that the packet is associated
              with no known connection
            </li>

            <li className="fragment" style={styles.li}>
              <strong>ESTABLISHED</strong> --- meaning that the packet is
              associated with a connection which has seen packets in both
              directions
            </li>
            <li className="fragment" style={styles.li}>
              <strong>NEW</strong>
              --- meaning that the packet has started a new connection, or
              otherwise associated with a connection which has not seen packets
              in both directions
            </li>
            <li className="fragment" style={styles.li}>
              <strong>RELATED</strong>
              --- meaning that the packet is starting a new connection, but is
              associated with an existing connection, such as an FTP data
              transfer, or an ICMP error
            </li>

            <li className="fragment" style={styles.li}>
              <strong>SNAT</strong> --- A virtual state, matching if the
              original source address differs from the reply destination
            </li>
            <li className="fragment" style={styles.li}>
              <strong>DNAT</strong> --- A virtual state, matching if the
              original destination differs from the reply source
            </li>
          </ul>
        </section>

        <section>
          <h1>Critères de filtrage interface</h1>
          <ul>
            <li className="fragment" style={styles.li}>
              Filtrage possible sur les interfaces:
            </li>
            <li className="fragment" style={styles.li}>
              Interface source
            </li>
            <li className="fragment" style={styles.li}>
              Interface de destination
            </li>
            <li className="fragment" style={styles.li}>
              Interface physique ou virtuelle (ou vlan)
            </li>
          </ul>
        </section>

        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Patterns communs</h1>
        </section>

        <section>
          <h1>DMZ classique</h1>
          <img className="r-stretch" src={dmzStandardImg} alt="dmz standard" />
        </section>

        <section>
          <h1>DMZ "double"</h1>
          <img
            className="r-stretch"
            style={{ backgroundColor: 'white' }}
            src={dmzFullImg}
            alt="dmz full"
          />
        </section>

        <section>
          <h1>LAN virtuel (site-to-site)</h1>
          <img
            className="r-stretch"
            style={{ backgroundColor: 'white' }}
            src={site2SiteImg}
            alt="site to site vpn"
          />
        </section>

        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Firewall linux</h1>
        </section>

        <section>
          <h1>netfilter</h1>
          <img className="r-stretch" src={netFilterImg} alt="tcp frame" />
        </section>

        <section>
          <h1>iptables</h1>
          <img className="r-stretch" src={iptablesImg} alt="iptables" />
        </section>

        <PracticalWork />
      </section>
    </>
  );
}
