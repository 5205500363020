import React from 'react';

export default function CyberDefinition() {
  return (
    <>
      <h2>Définition</h2>
      <blockquote>
        "La cybersécurité vise à protéger les systèmes informatiques, les
        serveurs et les réseaux contre les menaces, les accès non autorisés et
        les vulnérabilités, afin de garantir la disponibilité, l'intégrité et la
        confidentialité des données et des services."
      </blockquote>
      <span>
        - <strong>Chat GPT</strong>, Bot @ OpenAI Inc. -
      </span>
    </>
  );
}
