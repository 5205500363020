import React from 'react';

export default function GetIps() {
  return (
    <>
      <section data-auto-animate>
        <h2>GetIps.sh</h2>

        <pre>
          <code data-trim data-noescape className="bash">
            {`
  #!/bin/bash
  for machine in router admin sales dev dmz ldap filer intranet; do 
  
  # target DNS subdomain is target.milxc  
  hostname=$machine.target.milxc
  
  # Ping to force arp request
  ping $hostname -c 1 > /dev/null; 
  
  # Get IP from hostname
  ip=$(nslookup $hostname | awk '/^Address: / { print $2;exit; }'); 
  
  # Get mac addr from IP
  mac=$(arp -a | grep 100.80.0.4 | awk '{print $4}'); 
  
  echo -e "target-$machine -$ip - $mac"; 
  done
`}
          </code>
        </pre>
      </section>
    </>
  );
}
