import React from 'react';

export default function Protections() {
  const styles = {
    heading: {
      color: 'white'
    }
  };
  return (
    <section>
      <section data-background-color="CadetBlue" data-auto-animate>
        <h1 style={styles.heading}>Mécanismes de protection</h1>
      </section>
      <section data-auto-animate>
        <h2>Mécanismes de protection</h2>
        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column1">Nom</th>
                  <th className="column2 fill">Description</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="beautify-table-body">
            <table>
              <tbody>
                {[
                  {
                    name: 'Systèmes de détection des intrusions (IDS) ',
                    description:
                      "Monitoring en temps réel le trafic réseau et les activités des systèmes à la recherche de comportements anormaux ou de signes d'intrusion."
                  },
                  {
                    name: 'Systèmes de prévention des intrusions (IPS)',
                    description:
                      'Les IPS vont au-delà des IDS en bloquant activement les attaques plutôt que de simplement les signaler.'
                  },
                  {
                    name: 'Antivirus',
                    description:
                      'Logiciels détection et supression les logiciels malveillants.'
                  },
                  {
                    name: 'Firewall',
                    description:
                      'Comme une barrière entre plusieurs réseau.(Filtrage, Surveillance et controle du traffic)'
                  },
                  {
                    name: '...',
                    description: '...'
                  }
                ].map(({ name, description }, idx) => (
                  <tr key={idx} className="body fragment">
                    <td className="column1">{name}</td>
                    <td className="column2 fill">{description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </section>
  );
}
