import React from 'react';

export default function Question3() {
  return (
    <>
      <section>
        <h2>Question 3</h2>

        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column2 fill ">Question</th>
                  <th className="column2 fill ">Réponse</th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                <tr className="body">
                  <td className="column1 fragment">
                    Nous allons maintenant interdire toutes les connexions sur
                    le port 22 (SSH). Pour cela, il faut interdire dans la
                    chaîne INPUT les paquets TCP sur le port 22 avec la cible
                    DROP. <br />
                    <strong>Question 3:</strong> Quelle commande IPTables
                    avez-vous tapée ?
                  </td>
                  <td className="column2 fragment">
                    <pre>
                      <code data-trim data-noescape className="bash">
                        {`
   iptables -A INPUT -p tcp --dport 22 -j DROP
`}
                      </code>
                    </pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
