import React from 'react';
import Question12 from './Question1_2';
import Question3 from './Question3';
import Question4 from './Question4';
import Question5 from './Question5';
import Question6 from './Question6';
import Question7 from './Question7';
// import Segmentation from './Segmentation';
import segmentTableImg from '../../../assets/img/segmentation.png';
export default function Correct() {
  return (
    <>
      <Question12 />
      <Question3 />
      <Question4 />
      <Question5 />
      <Question6 />
      <Question7 />
      {/*<Segmentation />*/}
      <section>
        <h1>Segmentation</h1>
        <img className="r-stretch" src={segmentTableImg} alt="segment table" />
      </section>
    </>
  );
}
