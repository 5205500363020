import React from 'react';
import imgDataLeak from '../../../assets/img/dataleak.jpg';
import imgRansomware from '../../../assets/img/ransomware.jpg';
import imgDdos from '../../../assets/img/ddos.png';

export default function Risks() {
  const styles = {
    heading: {
      color: 'white'
    },
    riskBox: {
      display: 'flex',
      width: '75%'
    },
    riskBoxText: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left'
    }
  };

  const risksData = [
    {
      title: 'Exfiltration de données',
      description:
        "Fuite non autorisée d'informations sensibles vers des acteurs malveillants.",
      img: imgDataLeak
    },
    {
      title: 'Ransomware',
      description:
        'Attaques qui chiffrent les données et exigent une rançon pour les déchiffrer.',
      img: imgRansomware
    },
    {
      title: 'Interruption de service',
      description:
        'Les attaques par déni de service (DDoS) perturbant les opérations en saturant les ressources.',
      img: imgDdos
    }
  ];

  const knownAttacks = [
    {
      name: 'Stuxnet',
      description:
        'Stuxnet était un ver informatique découvert en 2010. Il a été conçu pour cibler spécifiquement les systèmes de contrôle industriel (SCADA) et aurait été développé pour perturber le programme nucléaire iranien.'
    },
    {
      name: 'NotPetya',
      description:
        'NotPetya était un ransomware destructif qui a provoqué des perturbations majeures en 2017. Il a affecté des entreprises et des systèmes gouvernementaux dans le monde entier.'
    },
    {
      name: 'SolarWinds',
      description:
        'En 2020, une attaque sophistiquée a ciblé le fournisseur de logiciels de gestion réseau SolarWinds. Les attaquants ont réussi à compromettre les mises à jour logicielles de SolarWinds, ce qui a permis de pirater de nombreuses organisations clientes.'
    },
    {
      name: 'Equifax',
      description:
        "En 2017, Equifax, l'une des principales agences de notation de crédit, a subi une violation de données majeure. Les données personnelles de millions de personnes ont été compromises."
    },
    {
      name: 'Heartbleed',
      description:
        'Heartbleed était une faille de sécurité critique découverte en 2014 dans la bibliothèque de chiffrement OpenSSL, utilisée par de nombreux sites Web. Elle a permis aux attaquants de voler des données sensibles.'
    },
    {
      name: 'Sony Pictures Entertainment',
      description:
        "En 2014, Sony Pictures Entertainment a été victime d'une cyberattaque majeure attribuée à la Corée du Nord. Des données sensibles ont été volées et des fichiers ont été diffusés en ligne."
    },
    {
      name: 'Mirai',
      description:
        'Le botnet Mirai a été responsable de nombreuses attaques DDoS en 2016, ciblant des appareils connectés à Internet, tels que les caméras de sécurité et les routeurs.'
    },
    {
      name: 'Wannacry',
      description:
        'WannaCry était un ransomware qui a infecté des milliers de systèmes en 2017, notamment des hôpitaux et des entreprises, en exploitant une vulnérabilité Windows non corrigée.'
    },
    {
      name: 'Mydoom',
      description:
        "Mydoom est l'un des plus anciens vers informatiques connus, apparu en 2004. Il a provoqué des perturbations massives en infectant des millions d'ordinateurs."
    },
    {
      name: 'SQL Slammer',
      description:
        'En 2003, le ver SQL Slammer a provoqué des perturbations majeures sur Internet en exploitant une vulnérabilité dans Microsoft SQL Server.'
    }
  ];

  return (
    <>
      <section>
        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Les risques</h1>
          <aside className="notes">
            Les risques en cybersécurité sont omniprésents et peuvent avoir des
            conséquences graves sur les organisations. Cette section explore les
            principaux types de risques, leurs conséquences et fournit des
            exemples concrets d'attaques.
          </aside>
        </section>

        <section>
          <h2>Types de risques</h2>
          {risksData.map((elt, idx) => (
            <div key={idx} style={styles.riskBox} className="fragment">
              <img src={elt.img} alt={elt.title} />
              <div style={styles.riskBoxText}>
                <h3>{elt.title}</h3>
                <p>{elt.description}</p>
              </div>
            </div>
          ))}
        </section>

        <section>
          <h2>Exemples concrets d'attaques</h2>
          <div className="beautify-table custom">
            <div className="beautify-table-head">
              <table>
                <thead>
                  <tr className="head">
                    <th className="column1">Nom</th>
                    <th className="column2 fill">Description</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="beautify-table-body">
              <table>
                <tbody>
                  {knownAttacks.map(({ name, description }, idx) => (
                    <tr key={idx} className="body">
                      <td className="column1">{name}</td>
                      <td className="column2 fill">{description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
