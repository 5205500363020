import React from 'react';
import pfSenseImg from '../../assets/img/pfsense.jpg';
import m0n0wallImg from '../../assets/img/m0n0wall.jpg';
import netGateImg from '../../assets/img/netgateFw.jpg';
import zimaboardImg from '../../assets/img/zimaBoard.png';
import thinkcentreImg from '../../assets/img/thinkcentre.jpg';
import serversImg from '../../assets/img/pizzabox.jpg';
import pfsenseBoxImg from '../../assets/img/pfsenseHardware.jpg';
import cloudImg from '../../assets/img/cloud.jpg';
import packageManagerImg from '../../assets/img/pfsense_package_manager.png';
import step1Img from '../../assets/img/pfSense_step1.png';
import step2Img from '../../assets/img/pfSense_step2.png';
import step3Img from '../../assets/img/pfSense_step3.png';
import step4Img from '../../assets/img/pfSense_step4.png';
import step5Img from '../../assets/img/pfSense_step5.png';
import step6Img from '../../assets/img/pfSense_step6.png';
import carpImg from '../../assets/img/carp.png';

export default function Content() {
  const styles = {
    heading: {
      color: 'white'
    },
    li: {
      marginBottom: '20px'
    },
    imgBig: {
      width: '30%',
      height: 'auto'
    },
    arrow: { fontSize: '3rem' },
    message: { marginBottom: '30px' }
  };
  return (
    <>
      <section>
        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>pfSense</h1>
        </section>
        <section data-auto-animate>
          <h1>Présentation</h1>
          <img className="r-stretch" src={pfSenseImg} alt="pfsense" />
        </section>

        <section data-auto-animate>
          <h1>Présentation</h1>
          <img src={pfSenseImg} className="r-stretch" alt="pfsense" />
          <ul>
            <li className="fragment" style={styles.li}>
              Système d'exploitation basé sur FreeBSD
            </li>
            <li className="fragment" style={styles.li}>
              Firewall / Routeur
            </li>
            <li className="fragment" style={styles.li}>
              Administration par interface WEB
            </li>
            <li className="fragment" style={styles.li}>
              VPN
            </li>
            <li className="fragment" style={styles.li}>
              Portail captif
            </li>
            <li className="fragment" style={styles.li}>
              NGFW
            </li>
          </ul>
        </section>

        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Origine</h1>
        </section>

        <section data-auto-animate>
          <h1>m0n0wall</h1>
          <img className="r-stretch" src={m0n0wallImg} alt="m0n0wall" />
        </section>

        <section data-auto-animate>
          <h1>m0n0wall</h1>
          <img className="r-stretch" src={m0n0wallImg} alt="m0n0wall" />
          <ul>
            <li className="fragment" style={styles.li}>
              Fork en 2004 du projet m0n0wall plutôt destiné à l'embarqué
            </li>
            <li className="fragment" style={styles.li}>
              Ambition d'être modulaire et ajouter des fonctionnalités
            </li>
            <li className="fragment" style={styles.li}>
              firewall, routeur, VPN, IDS/IPS
            </li>
            <li className="fragment" style={styles.li}>
              netgate : Contribution + Hardaware
            </li>
          </ul>
        </section>

        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Hardware</h1>
        </section>

        <section data-auto-animate>
          <h1>netgate</h1>
          <img className="r-stretch" src={m0n0wallImg} alt="m0n0wall" />
        </section>

        <section data-auto-animate>
          <h1>netgate</h1>
          <img className="r-stretch" src={netGateImg} alt="m0n0wall" />
          <ul>
            <li className="fragment" style={styles.li}>
              pfSense conçu pour être 100% compatible
            </li>
            <li className="fragment" style={styles.li}>
              Support technique (Pro)
            </li>
            <li className="fragment" style={styles.li}>
              Netgate & pfSense etroitement liés
            </li>
          </ul>
        </section>

        <section data-auto-animate>
          <h1>Machines phyisiques</h1>
          <div class="r-stack">
            <img class="fragment" src={zimaboardImg} alt="zimaboard" />
            <img class="fragment" src={thinkcentreImg} alt="thinkCentre" />
            <img class="fragment" src={pfsenseBoxImg} alt="pfSense box" />
            <img class="fragment" src={serversImg} alt="pizza box" />
          </div>
          <ul>
            <li style={styles.li}>Installable sur toutes machines</li>
            <li style={styles.li}>Idéal pour les petits réseaux</li>
            <li style={styles.li}>
              Convient également pour les grosse architectures
            </li>
          </ul>
        </section>

        <section data-auto-animate>
          <h1>Virtualisation</h1>
          <img className="r-stretch" src={cloudImg} alt="cloud" />
        </section>

        <section data-auto-animate>
          <h1>Virtualisation</h1>
          <img className="r-stretch" src={cloudImg} alt="cloud" />
          <ul>
            <li className="fragment" style={styles.li}>
              Hyperviseurs (ESXi, proxmox, kvm)
            </li>
            <li className="fragment" style={styles.li}>
              VMs classiques (virtualbox, hyperV)
            </li>
            <li className="fragment" style={styles.li}>
              Cloud providers
            </li>
          </ul>
        </section>

        <section data-auto-animate>
          <h1>Communauté</h1>
          <img
            className="r-stretch"
            src={packageManagerImg}
            alt="package manager"
          />
        </section>

        <section data-auto-animate>
          <h1>Communauté</h1>
          <img
            className="r-stretch"
            src={packageManagerImg}
            alt="package manager"
          />
          <ul>
            <li className="fragment" style={styles.li}>
              Contribution régulière de la communauté open source
            </li>
            <li className="fragment" style={styles.li}>
              Netgate trés actifs en cas de CVE
            </li>
            <li className="fragment" style={styles.li}>
              Package installables et très nombreux
            </li>
          </ul>
        </section>

        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Réalisation d'un lab pfSense</h1>
        </section>

        <section data-auto-animate>
          <h1>Déroulement</h1>
          <ul>
            <li className="fragment" style={styles.li}>
              Création de VMs sur virtualbox
            </li>
            <li className="fragment" style={styles.li}>
              Création de réseaux sur virtualbox
            </li>
            <li className="fragment" style={styles.li}>
              Objectif : Se familiariser avec l'interface de pfSense
            </li>
            <li>
              <a href="https://www.it-connect.fr/cours-tutoriels/administration-reseau/pare-feu/pfsense/">
                Tutoriels pfSense FR
              </a>
            </li>
          </ul>
        </section>

        <section data-auto-animate>
          <h1>Etape 1</h1>
          <img className="r-stretch" src={step1Img} alt="step 1" />
          <ul>
            <li className="fragment" style={styles.li}>
              Une VM basique avec un GUI
            </li>
            <li className="fragment" style={styles.li}>
              Une VM pfsense avec 3 NIC (pour l'instant uniquement 2 seront
              utilisées)
            </li>
            <li className="fragment" style={styles.li}>
              Réseau WAN = votre machine
            </li>

            <li className="fragment" style={styles.li}>
              <a href="https://www.it-connect.fr/comment-installer-pfsense-dans-virtualbox-pour-creer-un-lab-virtuel/">
                Tuto de mise en place
              </a>
            </li>
          </ul>
        </section>

        <section data-auto-animate>
          <h1>Etape 2</h1>
          <img className="r-stretch" src={step2Img} alt="step 2" />
          <ul>
            <li className="fragment" style={styles.li}>
              Mise en place d'un DHCP sur le LAN
            </li>
            <li className="fragment" style={styles.li}>
              Verification de la persistence de l'accès WEB
            </li>
          </ul>
        </section>
      </section>

      <section data-auto-animate>
        <h1>Etape 3</h1>
        <img className="r-stretch" src={step3Img} alt="step 3" />
        <ul>
          <li className="fragment" style={styles.li}>
            Création d'une 3ème VM headless
          </li>
          <li className="fragment" style={styles.li}>
            Mise en place d'un serveur web basique (python -m http.server)
          </li>
          <li className="fragment" style={styles.li}>
            DMZ isolée du LAN et du WAN
          </li>
        </ul>
      </section>

      <section data-auto-animate>
        <h1>Etape 4</h1>
        <img className="r-stretch" src={step4Img} alt="step 4" />
        <ul>
          <li className="fragment" style={styles.li}>
            Création d'un accès vpn depuis le WAN
          </li>
          <li className="fragment" style={styles.li}>
            <a href="https://www.it-connect.fr/pfsense-configurer-un-vpn-ssl-client-to-site-avec-openvpn/">
              Tuto de mise en oeuvre
            </a>
          </li>
        </ul>
      </section>

      <section data-auto-animate>
        <h1>Etape 5</h1>
        <img className="r-stretch" src={step5Img} alt="step 5" />
        <ul>
          <li className="fragment" style={styles.li}>
            Installation d'un serveur guacamole sur le serveur WEB pour accès
            ssh local
          </li>
          <li>Apache guacamole permet de faire du ssh/rdp/vnc en web</li>
          <li className="fragment" style={styles.li}>
            <a href="https://www.it-connect.fr/apache-guacamole-reverse-proxy-pfsense-haproxy/">
              Tuto a titre d'info (pas besoin de let's encrypt)
            </a>
          </li>
        </ul>
      </section>

      <section data-auto-animate>
        <h1>Clustering</h1>
        <img
          className="r-stretch"
          src={carpImg}
          alt="Common Address Redundancy Protocol
"
        />
        <ul>
          <li className="fragment">Common Address Redundancy Protocol</li>
          <li className="fragment">Haute disponibilité</li>
          <li className="fragment">2 IPs + 1 IP virtuelle</li>
          <li className="fragment">Autres protocoles : VRRP, HSRP, GLBP</li>
        </ul>
      </section>
      <section data-auto-animate>
        <h1>Etape 6</h1>
        <img className="r-stretch" src={step6Img} alt="step 6" />
        <ul>
          <li className="fragment" style={styles.li}>
            Mettre en place un deuxième pfsense
          </li>
          <li>Doubler chaque interface avec CARP</li>
          <li>Resources : </li>

          <li className="fragment" style={styles.li}>
            <a href="https://www.adminmalin.fr/cluster-pfsense-ha-utilisant-carp-failover/">
              https://www.adminmalin.fr/cluster-pfsense-ha-utilisant-carp-failover/
            </a>
          </li>
          <li className="fragment" style={styles.li}>
            <a href="https://notamax.be/pfsense-creation-dun-cluster/">
              https://notamax.be/pfsense-creation-dun-cluster/
            </a>
          </li>
        </ul>
      </section>
    </>
  );
}
