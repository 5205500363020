import React from 'react';

export default function Question4() {
  return (
    <>
      <section>
        <h2>Question 4</h2>
        Nous avons ici utilisé l'action DROP et le client SSH met un certain
        temps à s'apercevoir du refus.
        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column2 fill ">Question</th>
                  <th className="column2 fill ">Réponse</th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                <tr className="body">
                  <td className="column1 fragment">
                    Comprenez-vous pourquoi ?
                  </td>
                  <td className="column2 fragment">
                    Drop Jette le paquet sans prévenir l'émetteur. Le parcours
                    de la chaîne est interrompu. (Timeout)
                  </td>
                </tr>

                <tr className="body">
                  <td className="column1 fragment">
                    Comment changer ce comportement ?
                  </td>
                  <td className="column2 fragment">
                    Il faut remplacer par un reject
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <pre>
            <code data-trim data-noescape className="fragment bash">
              {`
    # Affiche la liste avec les numeros    
    iptables -L INPUT --line-numbers
    
    # replace de la regle
    iptables -R INPUT <Rule num>  -p tcp --dport 22 -j REJECT
    # exemple iptables -R INPUT 1 -p tcp --dport 22 -j REJECT

`}
            </code>
          </pre>
        </div>
      </section>
    </>
  );
}
