import React from 'react';

export default function WhoAreYou() {
  const styles = {
    li: {
      margin: '40px'
    }
  };

  return (
    <section id="who-are-you">
      <h1>Tour De Table</h1>
      <ul>
        <li style={styles.li}>Nom / Prénom</li>
        <li style={styles.li}>Formation Initiale / Alternance ?</li>
        <li style={styles.li}>
          Niveau <strong>Cyber/Réseau/Dev</strong>
        </li>
        <li style={styles.li}>Difficultés</li>
        <li style={styles.li}>Attentes</li>
      </ul>
    </section>
  );
}
