import React from 'react';
import { List } from '../../../Generic';

export default function Exercice() {
  return (
    <section>
      <h2>TP de prise en main de mi-lxc</h2>
      <List
        list={[
          'TP rédigé par le créateur de mi-lxc',
          'Familiarisation avec mi-lxc',
          "Création d'un AS (Autonoumous System) ",
          'Addressage réseau',
          'BGP',
          "Ne pas prendre en compte l'ipv6 pour l'exercice",
          <a href="https://git.kaz.bzh/francois.lesueur/R3.06/src/branch/master/td1-milxc.md">
            https://git.kaz.bzh/francois.lesueur/R3.06/src/branch/master/td1-milxc.md
          </a>
        ]}
      />
    </section>
  );
}
