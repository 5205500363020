import React from 'react';
import httpImg from '../../assets/img/http.png';
import httpsImg from '../../assets/img/https_encapsulation.png';
import laptopImg from '../../assets/img/laptop.jpg';
import caImg from '../../assets/img/ca_working.png';
import selfCaImg from '../../assets/img/self_ca.png';
import letsEncryptImg from '../../assets/img/lets_encrypt.jpg';
import httpChallenge from '../../assets/img/http_01_challenge.png';
import dnsChallenge from '../../assets/img/dns_challenge.png';
import crlImg from '../../assets/img/crl.png';
import reverseProxyImg from '../../assets/img/reverse-proxy.png';
import httpsReverseImg from '../../assets/img/https-reverse.png';
import nginxImg from '../../assets/img/nginx-logo.png';

export default function Content() {
  const styles = {
    heading: {
      color: 'white'
    },
    li: {
      marginTop: '20px'
    },
    imgBig: {
      width: '30%',
      height: 'auto'
    },
    arrow: { fontSize: '3rem' },
    message: { marginBottom: '30px' }
  };
  return (
    <>
      <section>
        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Fonctionnement</h1>
        </section>
        <section>
          <h1>Prérequis</h1>
          Fonctionnement de la cryptographie asymetrique et signature :
          <ul>
            <li className="fragment" style={styles.li}>
              Un messge chiffré avec la clé <strong>publique</strong> de{' '}
              <strong>Bob</strong> ne peut être déchifrée que par la clé{' '}
              <strong>privée</strong> de <strong>Bob</strong>
            </li>

            <li className="fragment" style={styles.li}>
              Quelqu'un connaissant la clé <strong>publique</strong> de{' '}
              <strong>Alice</strong> peut vérifier qu'un message (signature) a
              bien été crée par quelqu'un ayant accès à la clé{' '}
              <strong>privée</strong> de <strong>Alice</strong>
            </li>
          </ul>
        </section>

        <section>
          <h1 style={styles.heading}>HTTP</h1>
          <img style={{ backgroundColor: 'white' }} src={httpImg} alt="http" />
          <ul>
            <li className="fragment" style={styles.li}>
              Protocole non chiffré
            </li>
            <li className="fragment" style={styles.li}>
              Pas de verification de tiers (validation identité)
            </li>
          </ul>
        </section>

        <section>
          <h1 style={styles.heading}>HTTPS</h1>
          <img
            style={{ ...styles.imgBig, backgroundColor: 'white' }}
            src={httpsImg}
            alt="https"
          />
          <ul>
            <li className="fragment" style={styles.li}>
              Encapsulation par TLS (auparavant SSL)
            </li>
            <li className="fragment" style={styles.li}>
              Vérification de tiers (signature/certificat)
            </li>
            <li className="fragment" style={styles.li}>
              Notion d'autorité de certificaiton (<strong>CA</strong>)
            </li>
          </ul>
        </section>
        <section>
          <h1>Handshake HTTPS</h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
            className="http-container">
            <div style={{}} className="client">
              <div className="machine">
                {' '}
                <img style={{}} src={laptopImg} alt="client" />
                <p>Navigateur</p>
              </div>
              <div className="client-messages">
                <p className="fragment">
                  Google CA + clé publique connue. Verification
                </p>
              </div>
            </div>
            <div style={{}} className="messages">
              <div className="fragment">
                <span style={styles.arrow}>&#8594;</span>
                <p>Je veux accéder à youtube.com</p>
              </div>

              <div className="fragment">
                <span style={styles.arrow}>&#8592;</span>
                <p>
                  Voici mon certificat contenant ma clé publique. Il est signé
                  par Google CA
                </p>
              </div>

              <div className="fragment">
                <span style={styles.arrow}>&#8594;</span>
                <p>
                  Je fait confiance à la CA de google, tu es donc bien le
                  serveur avec qui je souhaite communiquer. Voici une clé
                  secrète que jái chiffré avec ta clé publique
                </p>
              </div>

              <div className="fragment message">
                <span style={styles.arrow}>&#8596;</span>
                <p>Utilisation de cette clé pour du chiffrement symetrique</p>
              </div>
            </div>

            <div style={{}} className="server ">
              <div className="machine">
                <img style={{}} src={laptopImg} alt="server" />
                <p>https://youtube.com</p>
              </div>
              <div className="client-messages"></div>
            </div>
          </div>
        </section>

        <section>
          <h2>Autorité de certification</h2>
          <img
            style={{ height: '80%', width: '80%', marginBottom: '70%' }}
            src={caImg}
            alt="ca"
          />
        </section>

        <section>
          <h2>Certificat auto signé</h2>
          <img
            style={{ height: '80%', width: '80%', marginBottom: '70%' }}
            src={selfCaImg}
            alt="self ca"
          />
        </section>

        <section>
          <h1>SSL/TLS</h1>
          <h2>SSL (Secure Sockets Layer)</h2>

          <ul>
            <li className="fragment">
              <strong>SSL 1.0 (Jamais publié publiquement):</strong>
              <ul>
                <li>Première version développée par Netscape.</li>
                <li>Jamais publiée en raison de vulnérabilités de sécurité.</li>
              </ul>
            </li>
            <li className="fragment">
              <strong>SSL 2.0 (1995):</strong>
              <ul>
                <li>Première version publique.</li>
                <li>
                  Vulnérabilités de sécurité identifiées, notamment des attaques
                  de type "POODLE".
                </li>
              </ul>
            </li>
            <li className="fragment">
              <strong>SSL 3.0 (1996):</strong>
              <ul>
                <li>Améliorations par rapport à SSL 2.0.</li>
                <li>
                  Vulnérabilités sérieuses, y compris les attaques de type
                  "POODLE" et "BEAST".
                </li>
              </ul>
            </li>
          </ul>
        </section>

        <section>
          <h2>TLS (Transport Layer Security)</h2>

          <ul>
            <li className="fragment">
              <strong>TLS 1.0 (1999):</strong>
              <ul>
                <li>Refonte majeure de SSL 3.0.</li>
                <li>Suppression des protocoles non sécurisés.</li>
                <li>Attaques "BEAST" contre les navigateurs.</li>
              </ul>
            </li>
            <li className="fragment">
              <strong>TLS 1.1 (2006):</strong>
              <ul>
                <li>Corrections de vulnérabilités de TLS 1.0.</li>
                <li>Amélioration des mécanismes de sécurité.</li>
              </ul>
            </li>
            <li className="fragment">
              <strong>TLS 1.2 (2008):</strong>
              <ul>
                <li>Intégration de modes de chiffrement plus forts.</li>
                <li>Ajout de nouvelles suites de chiffrement.</li>
                <li>Amélioration générale de la sécurité.</li>
              </ul>
            </li>
            <li className="fragment">
              <strong>TLS 1.3 (2018):</strong>
              <ul>
                <li>
                  Révision majeure avec des améliorations significatives de la
                  sécurité et des performances.
                </li>
                <li>
                  Suppression de fonctionnalités considérées comme obsolètes ou
                  peu sécurisées.
                </li>
                <li>
                  Réduction du nombre de tours de négociation pour accélérer
                  l'établissement de la connexion.
                </li>
              </ul>
            </li>
          </ul>
        </section>

        <section>
          <h1>Les listes de revocations de certificats (CRL)</h1>
          <img
            style={{ height: '50%', width: '50%', marginBottom: '70%' }}
            src={crlImg}
            alt="CRL"
          />
        </section>

        <section>
          <h1>Automatic Certificate Management Environment (ACME)</h1>
          <img
            style={{ height: '50%', width: '50%', marginBottom: '70%' }}
            src={letsEncryptImg}
            alt="letsencrypt"
          />
        </section>

        <section>
          <h1>HTTP-01 Challenge</h1>
          <img
            style={{ height: '80%', width: '80%', marginBottom: '70%' }}
            src={httpChallenge}
            alt="HTTP-01"
          />
        </section>

        <section>
          <h1>DNS-01 Challenge</h1>
          <img
            style={{ height: '70%', width: '70%', marginBottom: '70%' }}
            src={dnsChallenge}
            alt="DNS-01"
          />
        </section>

        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Reverse proxy</h1>
        </section>
        <section></section>
        <section>
          <h1>Reverse proxy</h1>
          <img
            style={{ height: '70%', width: '70%', marginBottom: '70%' }}
            src={reverseProxyImg}
            alt="Reverse proxy"
          />
        </section>
        <section>
          <h1>HTTPS to HTTP proxy </h1>
          <img
            style={{ height: '70%', width: '70%', marginBottom: '70%' }}
            src={httpsReverseImg}
            alt="https Reverse proxy"
          />
        </section>

        <section>
          <h1>Exemple d'outil</h1>
          <img
            style={{ height: '70%', width: '70%', marginBottom: '70%' }}
            src={nginxImg}
            alt="nginx"
          />
        </section>
        <section>
          <h1>TP</h1>
          <a href="https://git.kaz.bzh/francois.lesueur/LPDLIS/src/branch/master/tp-https.md">
            https://git.kaz.bzh/francois.lesueur/LPDLIS/src/branch/master/tp-https.md
          </a>
        </section>
      </section>
    </>
  );
}
