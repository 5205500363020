import React from 'react';
import Question1 from './Question1';
import Question23 from './Question23';
import GetIps from './GetIps';
import Scapy from './Scapy';
import Question456 from './Question456';
import Question78910 from './Question78910';
import Question11121314 from './Question11121314';

export default function Correct() {
  return (
    <>
      <Question1 />
      <GetIps />
      <Question23 />
      <Scapy />
      <Question456 />
      <Question78910 />
      <Question11121314 />
    </>
  );
}
