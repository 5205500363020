import React from 'react';

export default function Question11121314() {
  return (
    <>
      <section>
        <h2>Question 11-12-13-14</h2>

        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column2 fill ">Question</th>
                  <th className="column2 fill ">Réponse</th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 11:</strong> Verifier que le traffic est
                    bien visible par target-dev tout en étant fonctionnel côté
                    de l'ordinateur du commercial.
                  </td>
                </tr>

                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 12:</strong> Quels sont les impacts sur la
                    machine target-sales ? ( Réseau local / distant)
                  </td>
                  <td className="fragment">
                    Traffic subnet OK, Traffic externe NOK
                  </td>
                </tr>

                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 13:</strong> Proposez une ou plusieurs
                    solution afin d'éviter les attaques vues lors du TP.
                  </td>
                  <td className="fragment">
                    ARP statique / Protocoles chiffrés / Certificats
                  </td>
                </tr>

                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 14:</strong> A votre avis, pourquoi est-il
                    intéressant d'usurper un serveur DNS plutôt que le serveur
                    web ?
                  </td>
                  <td className="fragment">
                    Redirection vers des serveurs externe plutôt que le LAN
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
