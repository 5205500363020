import React from 'react';
import Planning from './Planning';
import ModuleGoal from './ModuleGoal';

export default function ModulePresentation() {
  const styles = {
    heading: {
      color: 'white'
    }
  };
  return (
    <>
      <section id="module-presentation">
        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>Contenu du module</h1>
        </section>

        <section>
          <ModuleGoal />
        </section>

        <section>
          <Planning />
          <aside className="notes">
            Le programme n'est pas fixé et il est suceptible de changer selon
            les besoins des étudiants.
          </aside>
        </section>
      </section>
    </>
  );
}
