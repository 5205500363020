import React from 'react';
import topologyImg from '../../../assets/img/milxc-topology.png';

export default function Topology() {
  const styles = {
    img: {
      width: '70%',
      height: 'auto'
    }
  };
  return (
    <section>
      <h2>Topologie</h2>
      <div>
        <img style={styles.img} data-src={topologyImg} alt="Mi-lxc topologie" />
      </div>
    </section>
  );
}
