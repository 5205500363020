import React from 'react';
import imgMilxc from '../../../assets/img/milxc.png';
import { List } from '../../../Generic';

export default function Presentation() {
  const styles = {
    imgMilxc: {
      width: '40%',
      height: 'auto'
    },
    bottomContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  };

  return (
    <>
      <section data-auto-animate>
        <h2>Présentation de MI-LXC</h2>
        <img style={styles.imgMilxc} src={imgMilxc} alt="Logo MI-LXC" />
      </section>
      <section data-auto-animate>
        <h2>Présentation de MI-LXC</h2>
        <div style={styles.bottomContainer}>
          <img style={styles.imgMilxc} src={imgMilxc} alt="Logo MI-LXC" />
          <List
            list={[
              'Plateforme pédagogique pour la sécurité des réseaux',
              'Mini Internet using LXC',
              'Francois Lesueur (INSA Lyon) ',
              'Sandbox',
              'Lightweight (VM < 3 Go )',
              'Modulaire',
              'Infrastructure As Code (YAML)',
              'Moteur SNSTER depuis v2.0'
            ]}
          />
        </div>
      </section>
    </>
  );
}
