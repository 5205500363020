import React from 'react';
import imgVbox from '../../../assets/img/vbox.png';
import { List } from '../../../Generic';

export default function Installation() {
  const styles = {
    img: {
      width: '30%',
      height: 'auto'
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },

    topContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    bottomContainer: {
      textAlign: 'left'
    }
  };

  return (
    <>
      <section>
        <h2>Installation</h2>
        <div style={styles.mainContainer}>
          <div style={styles.topContainer}>
            <img
              className="fragment"
              style={styles.imgMilxc}
              src={imgVbox}
              alt="Logo VirtualBox"
            />
            <List
              list={[
                <>
                  <span>Télécharger sur github la </span>
                  <a href="https://github.com/flesueur/mi-lxc/releases">
                    dernière version de la VM mi-lxc
                  </a>
                </>,
                'RAM par défaut à 3 Go (Réduire à 2 voir 1.5 Go si nécessaire)',
                <>
                  <p>Login de la vm root/root</p>
                  <a href="https://github.com/flesueur/mi-lxc/issues/9">
                    Pourquoi mi-lxc nécessite les privilèges root ?
                  </a>
                </>
              ]}
            />
          </div>
          <div style={styles.bottomContainer}>
            <h2 className="fragment">Alternatives</h2>
            <List
              list={[
                'Possible de convertir en .qcow2 pour KVM',
                <>
                  <a href="https://github.com/flesueur/mi-lxc/blob/master/doc/INSTALL.md#installation-on-windowsmacoslinux-using-vagrant">
                    Installation avec Vagrant
                  </a>
                </>,
                <>
                  <a href="https://github.com/flesueur/mi-lxc/blob/master/doc/INSTALL.md#installation-on-linux">
                    Installation directe système hôte
                  </a>
                </>
              ]}
            />
          </div>
        </div>
      </section>
    </>
  );
}
