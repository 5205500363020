import React from 'react';

export default function Question78910() {
  return (
    <>
      <section>
        <h2>Question 7-8-9-10</h2>

        <div className="beautify-table custom">
          <div className="beautify-table-head">
            <table>
              <thead>
                <tr className="head">
                  <th className="column2 fill ">Question</th>
                  <th className="column2 fill ">Réponse</th>
                </tr>
              </thead>
            </table>
          </div>
          <div style={{ maxHeight: 'unset' }} className="beautify-table-body">
            <table>
              <tbody>
                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 7:</strong> Depuis la machine du sysadmin,
                    faites un curl sur l'intranet. Obtenez-vous le résultat
                    escompté ? Comment le savez-vous ?
                  </td>
                  <td className="fragment">
                    Page web inchangé + rien sur urlsnarf
                  </td>
                </tr>

                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 8:</strong> Analysez le résultat du
                    tcpdump. Est-ce que des paquets HTTP ont été interceptés sur
                    notre machine ? Quelle est l'IP de destination ? Quelle est
                    l'IP de notre machine ?
                  </td>
                  <td className="fragment">
                    Paquets à destination de l'intranet non prise en compte
                  </td>
                </tr>

                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 9:</strong> Vérifiez à l'aide de ifconfig
                    que votre interface est correctement configurée. Relancez
                    curl. Que se passe-t-il ?
                  </td>
                  <td className="fragment">
                    La page web provient de la machine dev
                  </td>
                </tr>

                <tr className="body">
                  <td className="column2 fragment">
                    <strong>Question 10:</strong> Tentez d'expliquer pourquoi,
                    malgré l'ARP spoofing, vous aviez quand même une réponse
                    lors de la question 8.
                  </td>
                  <td className="fragment">
                    Par défaut linux FORWARD les paquets
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
