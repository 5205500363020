import React from 'react';
import imgVeolia from '../../assets/img/veolia.png';
import imgMel from '../../assets/img/mel.png';
import reactImg from '../../assets/img/react.png';
import nodeImg from '../../assets/img/node.png';
import goImg from '../../assets/img/go-logo-white.svg';
import archImg from '../../assets/img/archbtw.png';

export default function WhoAmI() {
  const styles = {
    archImg: {}
  };
  return (
    <>
      <section id="who-am-i" data-auto-animate>
        <h1>Présentation</h1>
      </section>

      <section id="who-am-i" data-auto-animate>
        <h1>Présentation</h1>
        <h3>Nathan FOURRIER</h3>
        <h4>Ingénieur en informatique industrielle</h4>
        <p>
          <strong>Veolia eau</strong>
          <br />
          <em>En charge du réseau industriel et sa cybersécurité</em>
          <br />
        </p>
        <img data-src={imgVeolia} alt="Veolia" />
        <img data-src={imgMel} alt="MEL" />
      </section>

      <section id="who-am-i" data-auto-animate>
        <h1>Présentation</h1>
        <h3>Développeur à mes heures perdues</h3>

        <br />
        <br />
        <img data-src={reactImg} alt="React" />
        <img data-src={nodeImg} alt="node" />
        <img data-src={goImg} alt="Golang" />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img style={styles.archImg} data-src={archImg} alt="I use arch btw" />
      </section>
    </>
  );
}
