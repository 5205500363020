import React from 'react';
import arpMsg from '../../../assets/img/red_ponyville_arp.gif';
import arpRequest from '../../../assets/img/arpRequest.png';
import arpTable from '../../../assets/img/arpTable.jpg';
import arpWireshark from '../../../assets/img/wiresharkArp.png';
import arpPacket from '../../../assets/img/arpPacketFormat.png';
import { List } from '../../../Generic';

export default function Arp() {
  const styles = {
    heading: {
      color: 'white'
    },
    img: {
      width: '30%',
      height: '30%'
    },
    imgBig: {
      width: '40%',
      height: '40%',
      marginBottom: '20%'
    }
  };
  return (
    <>
      <section>
        <section data-background-color="CadetBlue">
          <h1 style={styles.heading}>ARP</h1>
          <h2 style={styles.heading}>Address Resolution Protocol</h2>
        </section>

        <section>
          <h2>Envoi d'un paquet IP</h2>

          <List
            list={[
              "Destinataire d'un paquet IP identifié par une adresse IP",
              'Addresse mac nécessaire pour envoyer un paquet sur le réseau'
            ]}
          />
        </section>

        <section>
          <h2>Requête ARP</h2>
          <img style={styles.imgBig} src={arpRequest} alt="ARP Request" />
        </section>

        <section>
          <h2>Table ARP</h2>
          <img
            style={{ width: '70%', height: '90%' }}
            src={arpTable}
            alt="Arp Table"
          />
        </section>

        <section>
          <h2>$ arp -a</h2>
        </section>

        <section>
          <h2>Format paquet ARP</h2>
          <img
            style={{ width: '70%', height: '90%' }}
            src={arpPacket}
            alt="Arp Packet"
          />
        </section>
        <section>
          <h2>Messages ARP</h2>
          <img style={styles.imgBig} src={arpMsg} alt="Arp Msg" />
        </section>

        <section>
          <h2>Wireshark</h2>
          <img
            style={{ width: '70%', height: '90%' }}
            src={arpWireshark}
            alt="Arp WireShark"
          />
        </section>
      </section>
    </>
  );
}
