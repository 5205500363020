import React from 'react';

export default function Question7() {
  return (
    <>
      <section>
        <h2>Question 7</h2>
        <p>
          L'un des risques classiques, ensuite, est de scier sa branche pendant
          la configuration : écrivez via cette connexion SSH une règle qui
          bloque la connexion SSH d'administration et vous fait ainsi perdre la
          main sur la configuration du firewall...
        </p>
        <strong className="fragment">
          Question 7 : Proposez et exécutez une commande iptables qui bloque
          votre connexion SSH.
        </strong>
        <pre>
          <code data-trim data-noescape className="fragment bash">
            {`
    iptables -I INPUT -m comment --comment "Disable ssh" -p tcp --dport 22 -j REJECT

`}
          </code>
        </pre>
        <strong className="fragment">
          Comment parvenez-vous ensuite à vous débloquer ?
        </strong>
        <pre>
          <code data-trim data-noescape className="fragment bash">
            {`

    # Une fois sur le routeur physique on remove la première rule (celle insérée)
    iptables -D INPUT 1



`}
          </code>
        </pre>
      </section>
    </>
  );
}
