import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// RevealJS
import 'reveal.js/dist/reveal.css';
import 'reveal.js/dist/theme/blood.css';

// HighLightJS
import 'highlight.js/styles/stackoverflow-dark.css';

// StyleSheet
import './assets/css/index.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App width={1920} height={1080} slideNumber history controls />);
