import React from 'react';
import mitm from '../../../assets/img/mitm.jpg';
import mitmZine from '../../../assets/img/mitmZine.png';
import dos from '../../../assets/img/dos.jpg';

export default function Attacks() {
  const styles = {
    heading: {
      color: 'white'
    },
    img: {
      width: '30%',
      height: '30%'
    },
    imgBig: {
      width: '40%',
      height: '40%',
      marginBottom: '20%'
    }
  };
  return (
    <>
      <section data-background-color="CadetBlue">
        <h1 style={styles.heading}>Types d'attaques</h1>
      </section>
      <section>
        <h2>Usurpation</h2>

        <img
          style={{ ...styles.imgBig, backgroundColor: 'white' }}
          src={mitm}
          alt="mitm"
        />
      </section>

      <section>
        <h2>Denial of Service</h2>

        <img
          style={{ ...styles.imgBig, backgroundColor: 'white' }}
          src={dos}
          alt="DoS"
        />
      </section>

      <section>
        <h2>Sniffing</h2>

        <img
          style={{ ...styles.imgBig, backgroundColor: 'white' }}
          src={mitmZine}
          alt="mitm sniffing"
        />
      </section>
    </>
  );
}
